
require('@/assets/main.css');

import { ref, defineComponent } from 'vue';

document.title = "Tarren's Todo App";

interface Todo {
	task: string;
	done: boolean;
	id: number;
}

export default defineComponent({
	name: 'todo',
	setup() {
		const newTodo = ref('');
		const todos = ref<Todo[]>(JSON.parse(window.localStorage.getItem('todos') || '[]'));

		let theme = localStorage.getItem('theme');

		if (theme === 'dark') document.body.classList.add('dark');

		function toggleTheme() {
			if (localStorage.getItem('theme') == 'light') {
				document.body.classList.add('dark');
				localStorage.setItem('theme', 'dark');
				theme = 'dark';
			} else {
				document.body.classList.remove('dark');
				localStorage.setItem('theme', 'light');
			}
		}

		function save() {
			window.localStorage.setItem('todos', JSON.stringify(todos.value));
		}

		function addTodo() {
			todos.value.push({
				task: newTodo.value,
				done: false,
				id: Date.now()
			});
			newTodo.value = '';
			save();
		}

		function toggleDone(todo: Todo) {
			todo.done = !todo.done;
			save();
		}

		function removeTodo(index: number) {
			todos.value.splice(index, 1);
			save();
		}

		function markAllDone() {
			for (const todo of todos.value) {
				todo.done = true;
			}
			save();
		}

		function clearTodos() {
			todos.value = [];
			save();
		}

		return {
			toggleTheme,
			newTodo,
			addTodo,
			todos,
			toggleDone,
			removeTodo,
			markAllDone,
			clearTodos
		};
	}
});
